import { Breadcrumb, Col, Row } from 'antd';
import { H1, H1Bold, H2, H2Bold } from '../components/shared/typography';
import { Link, graphql } from 'gatsby';

import CollectionShop from '../components/collection.tsx/collection-shop';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Seo from '../components/Seo';
import styled from '@emotion/styled';
import { theme } from '../components/shared/theme';
import useWindowWidth from '../components/shared/useWindowSize';

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  max-height: 500px;
  background: ${theme.colors.brandLight.darkred};
`;

const HeadContainer = styled.div`
  justify-content: center;
  align-items: center;
  background: ${theme.colors.brandLight.darkred};
  z-index: 10;
  width: 100%;
  padding: 1rem;
  max-height: 800px;
  text-align: start;
  h2, p, span, text {
    text-align: start;
    color: white;
  }
  @media (max-width: 1000px) {
    padding: 1rem 10vw;
  }

  @media (max-width: 700px) {
    padding: 1rem 1rem;
  }
`

const HtmlContainer = styled.div`
  h3, h2, h1 {
    font-weight: bold;
    color: white;
  }
  font-size: 16px;
  line-height: 24px;
  color: white;
  
  &.mobile {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  
`;

const Collection: React.FC = ({ data: { collections } }: any) => {
  const collection = collections.nodes[0];
  const width = useWindowWidth()
  const type = collection.metafields.find((m: any) => m.key === 'type')
  
  return (
    <>
      <Seo
        title={collection.title}
        description={collection.description.slice(0, 155)}
        image={collection?.image?.originalSrc}
      />
      {/* <Breadcrumb style={{padding: '15px 1rem', maxWidth: '1400px', margin: 'auto'}}>
        <Breadcrumb.Item>
          <Link to="/vine">Vine</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{collection.title}</Breadcrumb.Item>
      </Breadcrumb> */}
      
      <div style={{height: '100px', padding: '2rem 1rem', background: theme.colors.brandLight.darkred, width: '100%'}}>
        <H1Bold style={{textAlign: 'center', color: 'white'}}>{collection.title}</H1Bold>
      </div>
      <Row style={{ position: 'relative', background: theme.colors.brandLight.darkred }} justify="center">
        <Col xs={{span: 24, order: 1}} md={{span: 12, order: 2}}>
          <StyledImg
              image={collection.image.gatsbyImageData}
              alt={collection.title}
              imgStyle={{ objectFit: 'contain', padding: '1rem'}}
            />
        </Col>
          <Col xs={{span: 24, order: 2}} md={{span: 12, order: 1}} >
            <HeadContainer>
                    <H2>Om {collection.title}</H2>
                    <HtmlContainer className={width > 766 ? "" : "mobile" }
                      dangerouslySetInnerHTML={{ __html: collection.descriptionHtml }}
                    />
                    {width < 766 && <a onClick={() => document.getElementById('mobileInfo')?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})}>Læs mere</a> }

            </HeadContainer>          
          </Col>
      </Row>
      <CollectionShop products={collection.products} title={collection.title} collectionType={type && type.value} />
      {width < 766 && 
        <Row style={{ position: 'relative', background: theme.colors.brandLight.darkred }} justify="center" id={'mobileInfo'}>
        <Col xs={{span: 24, order: 1}}>
          <StyledImg
              image={collection.image.gatsbyImageData}
              alt={collection.title}
              imgStyle={{ objectFit: 'contain'}}
            />
        </Col>
          <Col xs={{span: 24, order: 2}}>
            <HeadContainer>
                    <H2>Om {collection.title}</H2>
                    <HtmlContainer
                      dangerouslySetInnerHTML={{ __html: collection.descriptionHtml }}
                    />
            </HeadContainer>          
          </Col>
      </Row>
      }
    </>
  );
};
export default Collection;

export const query = graphql`
  query ($title: String!) {
    collections: allShopifyCollection(filter: { title: { eq: $title } }) {
      nodes {
        title
        descriptionHtml
        description
        productsCount
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 800)
          originalSrc
        }
        metafields {
          value
          key
        }
        products {
          descriptionHtml
          variants {
            storefrontId
            inventoryQuantity
            availableForSale
            compareAtPrice
            price
            selectedOptions {
              name
              value
            }
            image {
              gatsbyImageData
              originalSrc
            }
          }
          featuredImage {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 200)
            originalSrc
          }
          metafields {
            value
            key
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          title
          vendor
          tags
            collections {
            title
            metafields {
              value
            }
          }
        }
      }
    }
  }
`;
